<template>
  <section
    id="about-mission-vision"
    class="py-0"
  >
    <v-row
      class="mx-auto"
    >
      <v-col
        cols="1"
      />
      <v-col
        cols="10"
      >
        <base-left-photo
          :src="require('@/assets/Ronghao_logo.png')"
          subheading="RONGHAO ROBOT TECHNOLOGY"
          html="<b>RONGHAO (SHANDONG) ROBOT TECHNOLOGY CO., LTD.<b>는 산둥성 지보시 하이테크 존 칭룽산로 9009호에서 2021년 6월에 설립되었습니다. <br><br>당사는 자동화 기술 및 로봇 기술을 통한 스마트 공장 및 스마트 물류 솔루션을 제공합니다.<br><br>고객에게 최적의 비용 효율적이고 신뢰할 수 있는 제조 및 물류 솔루션을 제공하여 해당 업계에서 최고의 우위를 점할 수 있다고 확신합니다.<br><br>로봇 기술과 결합된 자동화는 인간의 실수를 줄일 뿐만 아니라 수율과 제조 생산성을 크게 향상시킬 것입니다.<br><br>RONGHAO (SHANDONG) ROBOT TECHNOLOGY CO., LTD.에서 자동화 및 인공 지능의 힘을 활용하여 비즈니스를 혁신하십시오."
          color="black--text"
        />
      </v-col>
      <v-col
        cols="1"
      />
    </v-row>
  </section>
</template>

<script>
  export default {
    name: 'SectionAboutMissionVision',
  }
</script>
